import Vue from "vue";
import Vuex from "vuex";
import colors from "./ColorModule";
import modals from "./Modals";
import builder from "./Builder";
import temporaryCloseableElements from "./TemporaryCloseableElements";
import router from "../router";
import axios from "axios";
import moment from "moment";
import {
  managedEvent,
  userTourPasses,
  activeScorecards,
  favouriteEventIds,
  groupEditor,
  managerMenu,
} from "./StoreQueries";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    colors,
    modals,
    builder,
    temporaryCloseableElements,
  },
  state: {
    userSubscription: null,
    subscriptionModalTexts: {
      textToShow: "",
      groupScoring: "Upgrade to the Pro plan to start using Group Scoring.",
      verifier: "Upgrade to the Pro plan to start using the Verifier.",
      signature: "Upgrade now to start using the Signature.",
      autoOpen: "Upgrade now to start using registration opening automation.",
      reigstrationType: "Upgrade now to start using group signup.",
      checkIn: "Upgrade now to start using Check-in.",
      rounds: "Upgrade now to add 2 rounds.",
      unlimitedRounds: "Upgrade to the Pro plan to add more than 2 rounds.",
      pools: "Upgrade to the Pro plan to start using Pools.",
      startingFormat: "Upgrade now to set teetime starts.",
      playerExport: "Upgrade to the Pro plan to access the player export.",
      resultExport: "Upgrade to the Pro plan to access the results export.",
      standingsExport: "Upgrade now to access the standings export.",
      tiebreaker: "Upgrade to the Pro plan to set tiebreaks.",
      stages: "Upgrade to the Pro plan to start using registration stages.",
      questions:
        "Upgrade to the Pro plan to start using registration questions.",
      dynamicPoints: "Upgrade now to start using dynamic points.",
      customPoints: "Upgrade now to start using custom points.",
      documents: "Upgrade to the Pro plan to upload documents.",
      pause: "Upgrade to the Pro plan to Pause pools and rounds.",
      marshall: "Upgrade to the Pro plan to start using Marshalls.",
    },
    returnToRoute: null,
    groupEditorData: null,
    managerMenuData: null,
    messages: {
      show: false,
      message: "",
      timer: 1500,
      type: "success",
      style: "midnight",
    },
    currencyMinimumChargeAmount: {
      SEK: 3,
      DKK: 2.5,
      NOK: 3,
      AUD: 0.5,
      GBP: 0.3,
      EUR: 0.5,
      USD: 0.5,
    },
    currencyFormats: {
      SEK: { code: "SEK", symbol: "kr" },
      DKK: { code: "DKK", symbol: "kr" },
      NOK: { code: "NOK", symbol: "kr" },
      AUD: { code: "AUD", symbol: "$" },
      GBP: { code: "GBP", symbol: "£" },
      EUR: { code: "EUR", symbol: "€" },
      USD: { code: "USD", symbol: "$" },
      JPY: { code: "JPY", symbol: "¥" },
      NZD: { code: "NZD", symbol: "$" },
    },
    currentlyManagedEvent: {
      data: null,
      myCourses: null,
      loaded: false,
    },
    eventDivisionPools: [],
    eventMessages: [],
    eventMessagesMeta: null,
    eventInformation: {
      showEventInfo: false,
      showInfo: false,
      details: {},
    },
    poolConversion: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
    loginProcess: {
      invalidEmail: false,
      invalidPassword: false,
    },
    eventDivisions: [],
    user: {
      watchedEvents: [],
      watchedEventsLoaded: false,
      tourPasses: [],
      registrations: [],
      favouriteEvents: [],
      favouriteEventsIds: [],
      isAuthenticated: false,
      authCheckCompleted: false,
      isVerified: false,
      emailVerifyInformation: {
        showBanner: false,
        closedDateTime: {},
      },
      eventRegistrations: [],
      sessionInfo: {
        expireAt: "",
        token: "",
        userId: "",
      },
      isMarshall: [],
      openPools: [],
      scorecards: [],
      firstName: "",
      lastName: "",
      email: "",
    },
    utility: {
      showTopNav: true,
      showFooter: true,
      showMobileMenu: false,
      deviceWidth: 0,
      escapePressed: false,
      loading: false,
      loadingTimeStamp: moment().valueOf(),
      onTjingMobile: navigator.userAgent.includes("tjing-mobile-app"),
    },
  },
  mutations: {
    validateUserAuthState(state, payload) {
      state.user.isAuthenticated = payload;
      state.user.authCheckCompleted = true;
    },
    updateEventDivisionPools(state, payload) {
      state.eventDivisionPools = payload;
    },
    updateSubscriptionStatus(state, payload) {
      state.userSubscription = payload;
    },
    setSubscriptionModalTextToUse(state, payload) {
      state.subscriptionModalTexts.textToShow = payload;
    },
    loadGroupEditorData(state, payload) {
      state.groupEditorData = payload;
    },
    loadManagerMenuData(state, payload) {
      state.managerMenuData = payload;
    },

    async loadFavouriteEventsIds(state) {
      try {
        const favouriteEvents = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: favouriteEventIds,
          },
        });

        state.user.favouriteEventsIds =
          favouriteEvents.data.data.me.favouriteEvents;
      } catch (err) {
        console.log(err);
      }
    },
    async loadFavouriteEvents(state) {
      try {
        const favouriteEvents = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: favouriteEvents,
          },
        });

        state.user.favouriteEvents =
          favouriteEvents.data.data.me.favouriteEvents;
      } catch (err) {
        console.log(err);
      }
    },
    setEventMessages(state, eventMessages) {
      state.eventMessages = eventMessages;
    },
    setEventMessagesMeta(state, payload) {
      state.eventMessagesMeta = payload;
    },
    setReturnToRouteData(state, routeInfo) {
      state.returnToRoute = routeInfo;
    },
    showMessage(state, messageObject) {
      state.messages.message = messageObject.message;
      state.messages.type = messageObject.type;
      state.messages.style = messageObject.style;
      state.messages.timer =
        messageObject.timer === undefined ? 4000 : messageObject.timer;
      state.messages.show = true;
    },

    hideMessage(state) {
      state.messages.show = false;
      state.messages.timer = 1500;
    },
    hideEmailVerificationBanner(state) {
      state.user.emailVerifyInformation.showBanner = false;
      state.user.emailVerifyInformation.closedDateTime = moment();
    },
    authenticationState(state, bool) {
      state.user.isAuthenticated = bool;
    },
    toggleMobileMenu(state, bool) {
      state.utility.showMobileMenu = bool;
    },
    setFooterState(state, bool) {
      state.utility.showFooter = bool;
    },
    setMainNavState(state, bool) {
      state.utility.showTopNav = bool;
    },
    showEventInfo(state, bool) {
      state.eventInformation.showEventInfo = bool;
    },
    showEventInfoBody(state, bool) {
      state.eventInformation.showInfo = bool;
    },
    setEventInfo(state, information) {
      state.eventInformation.details = information;
    },
    toggleLoading(state, bool) {
      let timer = moment().valueOf() - state.utility.loadingTimeStamp;

      if (bool == true) {
        state.utility.loadingTimeStamp = moment().valueOf();
        state.utility.loading = bool;

        setTimeout(() => {
          state.utility.loading = false;
        }, 3000);
      }

      if (timer < 1750 && bool == false) {
        setTimeout(() => {
          state.utility.loading = false;
        }, 1750 - timer);
      }
    },
    escapePressed(state) {
      state.utility.escapePressed = true;
      Vue.nextTick(function () {
        state.utility.escapePressed = false;
      });
    },
    deviceWidth(state, payload) {
      state.utility.deviceWidth = payload;
    },
    logout(state) {
      localStorage.removeItem("userObject");
      state.user = {
        authCheckCompleted: false,
        watchedEvents: [],
        watchedEventsLoaded: false,
        tourPasses: [],
        registrations: [],
        isAuthenticated: false,
        isVerified: false,
        emailVerifyInformation: {
          showBanner: false,
          closedDateTime: {},
        },
        eventRegistrations: [],
        sessionInfo: {
          expireAt: "",
          token: "",
          userId: "",
        },
        isMarshall: [],
        openPools: [],
        scorecards: [],
        firstName: "",
        lastName: "",
        email: "",
      };

      document.cookie =
        "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    setSessionInfo(state, sessionInfo) {
      state.user.firstName = sessionInfo.firstName;
      state.user.lastName = sessionInfo.lastName;
      state.user.email = sessionInfo.email;
      state.user.sessionInfo = sessionInfo;
      state.utility.escapePressed = true;
      Vue.nextTick(function () {
        state.utility.escapePressed = false;
      });
    },
    setUserInfo(state, userInfo) {
      state.user.firstName = userInfo.firstName;
      state.user.lastName = userInfo.lastName;
      state.user.email = userInfo.email;
    },
    setEventDivisions(state, divisions) {
      state.eventDivisions = divisions;
    },
    setMarshalls(state, marshall) {
      state.user.isMarshall = marshall;
    },
    updateProfileImage(state, imageUrl) {
      state.user.sessionInfo.profileImageUrl = imageUrl;
    },
    removeWatchedEvent(state, event) {
      state.user.watchedEvents = state.user.watchedEvents.filter((watched) => {
        return watched != event;
      });
    },
    addWatchedEvent(state, event) {
      state.user.watchedEvents.push(event);
    },
    async getUserTourPasses(state, payload) {
      state.user.tourPasses = payload;
    },

    async showVerifyEmailBanner(state, payload) {
      if (state.utility.onTjingMobile) {
        state.user.emailVerifyInformation.showBanner = false;
      } else {
        state.user.emailVerifyInformation.showBanner = payload;
      }
    },
    activeScorecards(state) {
      axios({
        headers: {
          Authorization: state.user.sessionInfo.token,
        },
        data: {
          query: activeScorecards,
        },
      })
        .then((result) => {
          state.user.openPools = [];

          if (result.data.data.me.isVerified == false) {
            if (localStorage.getItem("dataFetchedTimestamps")) {
              let timeStamps = JSON.parse(
                localStorage.getItem("dataFetchedTimestamps")
              );

              if (timeStamps.closedDateTime) {
                if (
                  moment(timeStamps.closedDateTime).add(7, "days").isBefore()
                ) {
                  state.user.emailVerifyInformation.showBanner = true;
                }
              } else {
                state.user.emailVerifyInformation.showBanner = true;
              }
            } else {
              state.user.emailVerifyInformation.showBanner = true;
            }
          }
          if (state.utility.onTjingMobile) {
            state.user.emailVerifyInformation.showBanner = false;
          }
          state.user.scorecards = [
            ...result.data.data.me.groups,
            ...result.data.data.me.teamGroups,
          ];
          for (var i = 0; i < result.data.data.me.marshalls.length; i++) {
            state.user.isMarshall.push(result.data.data.me.marshalls[i]);
          }
        })
        .catch(() => {});
    },
    async loadManagedEventData(state, payload) {
      try {
        let eventData = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: managedEvent(payload),
          },
        });

        eventData.data.data.event.rounds.forEach((round) => {
          round.pools.forEach((pool) => (pool.createGroupsMethod = "RANDOM"));
        });

        state.currentlyManagedEvent.data = eventData.data.data.event;
        state.currentlyManagedEvent.myCourses = eventData.data.data.me.courses;
        state.currentlyManagedEvent.loaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
  actions: {
    updateEventDivisionPools(action, payload) {
      action.commit("updateEventDivisionPools", payload);
    },
    async validateUserAuthState(action, token) {
      try {
        const authState = await axios({
          headers: { Authorization: token },
          data: {
            query: `{
              isAuthenticated
              me {
                id
                email
                isVerified
                firstName
                lastName
                profile{
                  profileImageUrl
                }
                subscription{
                  status
                  tier
                }
              }
            }`,
          },
        });

        if (authState.data.data.isAuthenticated === false) {
          action.dispatch("logout");
        } else {
          let subscriptionTier = "FREE";

          const hasActiveSubscription =
            authState.data.data.me.subscription?.status === "active";
          if (hasActiveSubscription) {
            subscriptionTier = authState.data.data.me.subscription.tier;
          }
          const sessionInfo = {
            email: authState.data.data.me.email,
            firstName: authState.data.data.me.firstName,
            lastName: authState.data.data.me.lastName,
            profileImageUrl: authState.data.data.me.profile.profileImageUrl,
            token,
            userId: authState.data.data.me.id,
            subscriptionTier: subscriptionTier,
          };

          action.commit("setSessionInfo", sessionInfo);

          document.cookie = `authToken=${token}; Secure; expires=${new Date(
            2147483647 * 1000
          ).toUTCString()}; path=/`;

          action.commit(
            "validateUserAuthState",
            authState.data.data.isAuthenticated
          );

          if (authState.data.data.me.isVerified == false) {
            action.commit("showVerifyEmailBanner", true);
          }

          //localStorage.removeItem("userObject");
        }

        return authState;
      } catch (err) {
        console.log(err);
      }
    },
    updateSubscriptionStatus(action, payload) {
      action.commit("updateSubscriptionStatus", payload);
    },
    setSubscriptionModalTextToUse(action, payload) {
      action.commit("setSubscriptionModalTextToUse", payload);
    },
    async getUserTourPasses({ commit, state }, payload) {
      try {
        const tourPasses = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: userTourPasses,
          },
        });

        commit("getUserTourPasses", tourPasses.data.data.me.tourPasses);
        return tourPasses;
      } catch (err) {
        console.log(err);
      }
    },
    async loadGroupEditorData({ commit, state }, payload) {
      try {
        const groupEditorData = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: groupEditor(payload),
          },
        });

        groupEditorData.data.data.event.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            pool.defaultNewStartHole = pool.layoutVersion.holes[0].id;
            pool.showOptions = false;
            pool.teeTimeSortOrder = "asc";
          });
        });

        commit("loadGroupEditorData", groupEditorData.data.data.event);
        commit(
          "setEventDivisions",
          groupEditorData.data.data.event.tour.divisions
        );

        return groupEditorData;
      } catch (err) {
        console.log(err);
      }
    },
    async loadManagerMenuData({ commit, state }, payload) {
      try {
        const managerMenuData = await axios({
          headers: { Authorization: state.user.sessionInfo.token },
          data: {
            query: managerMenu(payload),
          },
        });

        commit("loadManagerMenuData", managerMenuData.data.data.event);

        return managerMenuData;
      } catch (err) {
        console.log(err);
      }
    },
    loadFavouriteEventsIds(action) {
      action.commit("loadFavouriteEventsIds");
    },
    loadFavouriteEvents(action) {
      action.commit("loadFavouriteEvents");
    },
    setEventMessages(action, eventMessages) {
      action.commit("setEventMessages", eventMessages);
    },
    setEventMessagesMeta(action, payload) {
      action.commit("setEventMessagesMeta", payload);
    },
    setReturnToRouteData(action, routeInfo) {
      action.commit("setReturnToRouteData", routeInfo);
    },
    removeWatchedEvent(action, eventId) {
      action.commit("removeWatchedEvent", eventId);
    },
    addWatchedEvent(action, eventId) {
      action.commit("addWatchedEvent", eventId);
    },
    loadManagedEventData(action, payload) {
      action.commit("loadManagedEventData", payload);
    },
    disableEmailVerificationBanner(action) {
      action.commit("hideEmailVerificationBanner");
    },
    hideEmailVerificationBanner(action) {
      if (localStorage.getItem("dataFetchedTimestamps")) {
        let timeStamps = JSON.parse(
          localStorage.getItem("dataFetchedTimestamps")
        );
        timeStamps.closedDateTime = moment();
        localStorage.setItem(
          "dataFetchedTimestamps",
          JSON.stringify(timeStamps)
        );
        action.commit("hideEmailVerificationBanner");
      } else {
        let timeStamps = {
          closedDateTime: moment(),
        };

        localStorage.setItem(
          "dataFetchedTimestamps",
          JSON.stringify(timeStamps)
        );
        action.commit("hideEmailVerificationBanner");
      }
    },
    showMessage(action, messageObject) {
      action.commit("showMessage", messageObject);
    },
    hideMessage(action) {
      action.commit("hideMessage");
    },
    authenticationState(action, bool) {
      action.commit("authenticationState", bool);
    },
    toggleMobileMenu(action, bool) {
      action.commit("toggleMobileMenu", bool);
    },
    checkForOpenGroups(action, bypass) {
      if (localStorage.getItem("dataFetchedTimestamps")) {
        let timeStamps = JSON.parse(
          localStorage.getItem("dataFetchedTimestamps")
        );

        if (
          moment(timeStamps.scorecardCheck).add(30, "seconds").isBefore() ||
          bypass
        ) {
          action.commit("activeScorecards");

          timeStamps.scorecardCheck = moment();
          localStorage.setItem(
            "dataFetchedTimestamps",
            JSON.stringify(timeStamps)
          );
        }
      } else {
        let timeStamps = {
          scorecardCheck: moment(),
        };

        localStorage.setItem(
          "dataFetchedTimestamps",
          JSON.stringify(timeStamps)
        );
      }
    },
    updateProfileImage(action, imageUrl) {
      action.commit("updateProfileImage", imageUrl);
    },
    setMarshalls(action, marshall) {
      action.commit("setMarshalls", marshall);
    },
    setFooterState(action, bool) {
      action.commit("setFooterState", bool);
    },
    setMainNavState(action, bool) {
      action.commit("setMainNavState", bool);
    },
    setEventInfo(action, information) {
      action.commit("setEventInfo", information);
    },
    showEventInfo(action, bool) {
      action.commit("showEventInfo", bool);
    },
    showEventInfoBody(action, bool) {
      action.commit("showEventInfoBody", bool);
    },
    setEventDivisions(action, divisions) {
      action.commit("setEventDivisions", divisions);
    },
    setSessionInfo(action, sessionInfo) {
      action.commit("setSessionInfo", sessionInfo);
    },
    setUserInfo(action, userInfo) {
      action.commit("setUserInfo", userInfo);
    },
    toggleLoading(action, bool) {
      action.commit("toggleLoading", bool);
    },
    escapePressed(action) {
      action.commit("escapePressed");
    },
    deviceWidth(action, payload) {
      action.commit("deviceWidth", payload);
    },
    logout(action) {
      if (router.currentRoute.path !== "/") {
        router.push({ path: "/" });
      }

      setTimeout(() => {
        action.commit("logout");
      }, 750);
    },
  },
  getters: {
    eventDivisionPools: (state) => {
      return state.eventDivisionPools;
    },
    subscriptionTier(state) {
      return state.user.sessionInfo.subscriptionTier;
    },
    hasActiveSubscription(state) {
      return state.userSubscription
        ? state.userSubscription.status === "active"
        : false;
    },
    getManagerMenuData(state) {
      return state.managerMenuData;
    },

    getGroupEditorData(state) {
      return state.groupEditorData;
    },
    favouriteEventIds: (state) => {
      return state.user.favouriteEventsIds || [];
    },
    favouriteEvents: (state) => {
      return state.user.favouriteEvents;
    },
    eventMessages: (state) => {
      return state.eventMessages;
    },
    onTjingMobile: (state) => {
      return state.utility.onTjingMobile;
    },
    returnToRoute: (state) => {
      return state.returnToRoute;
    },
    watchedEvents: (state) => {
      return state.user.watchedEvents;
    },
    watchedEventsLoaded: (state) => {
      return state.user.watchedEventsLoaded;
    },
    managedEvent: (state) => {
      return state.currentlyManagedEvent;
    },
    deviceWidth: (state) => {
      return state.utility.deviceWidth;
    },
    showEmailVerificationBanner: (state) => {
      return state.user.emailVerifyInformation.showBanner;
    },
    registrations: (state) => {
      return state.user.registrations;
    },
    tourPasses: (state) => {
      return state.user.tourPasses;
    },
    eventDivisions: (state) => {
      return state.eventDivisions;
    },
    showMessage: (state) => {
      return state.messages;
    },
    showItems: (state) => {
      return state.utility;
    },
    openPools: (state) => {
      return state.user.openPools;
    },
    marshallGroups: (state) => {
      return state.user.isMarshall;
    },
    showMobileMenu: (state) => {
      return state.utility.showMobileMenu;
    },
    userScorecards: (state) => {
      return state.user.scorecards;
    },
    showFooter: (state) => {
      return state.utility.showFooter;
    },
    showTopNav: (state) => {
      return state.utility.showTopNav;
    },
    eventInformation: (state) => {
      return state.eventInformation;
    },
    userInitials: (state, getters) => {
      if (getters.isUserLoggedIn) {
        return state.user.firstName.charAt(0) + state.user.lastName.charAt(0);
      }
    },
    escapePressed: (state) => {
      return state.utility.escapePressed;
    },
    userInfo: (state) => {
      return state.user;
    },
    isUserLoggedIn: (state) => {
      return state.user.isAuthenticated;
    },
    authCheckCompleted: (state) => {
      return state.user.authCheckCompleted;
    },
  },
});
